<template>
  <Wrap class="sm">
    <div class="LoginForgot">
      <FormFloat title="Slaptažodžio atstatymas" @submit="sendEmail">
        <transition name="slide-down">
          <Notice v-if="message" :class="message.theme">
            {{ message.text }}
          </Notice>
        </transition>
        <template v-if="!hasSent">
          <!-- <FieldManualCaptcha :value.sync="manualCaptcha"/> -->
          <Field
            autocomplete="email"
            label="El. paštas"
            required
            type="email"
            :value.sync="email" />
          <NoticeRecaptcha />
          <Btn class="arrow" :isLoading="isLoading" type="submit">
            Atkurti slaptažodį
          </Btn>
        </template>
      </FormFloat>
    </div>
  </Wrap>
</template>

<script>
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import Meta from './mixins/Meta'
import Recaptcha from './mixins/Recaptcha'
import { actions } from './utils'

export default {
  mixins: [
    Message,
    Meta(
      'Pamiršau slaptažodį',
      'Užmiršote slaptažodį? Ne problema! Galime atsiųsti naujo slaptažodžio nustatytmo nuorodą jums į el. paštą.',
      require('@/assets/social/login-forgot.jpg'),
    ),
    Recaptcha,
  ],
  components: { FormFloat },
  data() {
    return {
      email: '',
      isLoading: false,
      hasSent: false,
    }
  },
  methods: {
    async sendEmail() {
      this.success('Siunčiame slaptažodžio pakeitimo laišką...')

      try {
        this.isLoading = true
        await this.PATCH_AUTHMANAGEMENT(await this.injectRecaptcha({
          id: null,
          email: this.email,
          isResetTokenRequest: true,
        }, 'resetPassword'))
        this.hasSent = true
        this.success('Puiku! Patikrink savo el. paštą su tolimesnėmis instrukcijomis!')
      } catch (error) {
        this.danger(error.message || 'Jau išsiuntėme slaptažodžio pakeitimo laišką.')
      } finally {
        this.isLoading = false
      }
    },
    ...actions('PATCH_AUTHMANAGEMENT'),
  },
}
</script>

<style lang="scss">
.LoginForgot {
  background: $white;
  border-radius: $radius 0;
}
</style>
