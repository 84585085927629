<template>
  <Wrap class="sm">
    <div class="LoginReset">
      <FormFloat title="Pakeisti slaptažodį" @submit="save">
        <Notice v-if="message" :class="message.theme">
          {{ message.text }}
        </Notice>
        <Field
          v-if="!hasChanged"
          autocomplete="new-password"
          label="Naujas slaptažodis"
          rules="password|passwordNumber|passwordNotAllNumeric"
          type="password"
          :value.sync="password" />
        <Btn v-show="!hasChanged" class="Btn cta arrow" :isLoading="isLoading" type="submit">
          Nustatyti naują slaptažodį
        </Btn>
        <Route v-show="hasChanged" class="Btn cta arrow" to="Login">
          Prisijungti
        </Route>
      </FormFloat>
    </div>
  </Wrap>
</template>

<script>
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import Meta from './mixins/Meta'
import { actions } from './utils'

export default {
  mixins: [
    Message,
    Meta(
      'Slaptažodžio atkūrimas',
      'Užmiršote slaptažodį? Ne problema! Galime atsiųsti naujo slaptažodžio nustatytmo nuorodą jums į el. paštą.',
      require('@/assets/social/login-forgot.jpg'),
    ),
  ],
  components: { FormFloat },
  data() {
    return {
      password: '',
      isLoading: false,
      hasChanged: false,
    }
  },
  computed: {
    resetToken() {
      return this.$route.query.token
    },
  },
  methods: {
    async save() {
      try {
        this.isLoading = true
        await this.PATCH_AUTHMANAGEMENT({
          id: null,
          password: this.password,
          resetToken: this.resetToken,
        })
        this.hasChanged = true
        this.success('Slaptažodis pakeistas!')
      } catch (error) {
        this.setErrorMessage(error)
      } finally {
        this.isLoading = false
      }
    },
    ...actions('LOGIN', 'PATCH_AUTHMANAGEMENT'),
  },
}
</script>

<style lang="scss">
.LoginReset {
  background: $white;
  border-radius: $radius 0;
}
</style>
