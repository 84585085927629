<template>
  <Wrap class="sm">
    <div class="SignupThanks">
      <FormFloat
        title="Sėkmingai prisiregistravote">
        <Notice v-if="message" :class="message.theme">
          {{ message.text }}
        </Notice>
        <Btn v-if="signupEmail" :disabled="verifyAskedDiff > 0" @click="reverify">
          Iš naujo siųsti patvirtinimo laišką
          <template v-if="verifyAskedDiff > 0">
            ({{ verifyAskedDiff }})
          </template>
        </Btn>
        <Row>
          <Column :sm="6">
            <Btn class="success" to="Login">
              Prisijungti
            </Btn>
          </Column>
          <Column :sm="6">
            <Btn class="neutral" to="Index">
              Į pagrindinį
            </Btn>
          </Column>
        </Row>
      </FormFloat>
    </div>
  </Wrap>
</template>

<script>
import { actions, getters } from './utils'
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import Meta from './mixins/Meta'

export default {
  mixins: [
    Message,
    Meta('Registracija pavyko'),
  ],
  components: { FormFloat },
  data() {
    return {
      interval: null,
      nowTime: Date.now(),
      verifyAskedAt: null,
    }
  },
  computed: {
    verifyAskedDiff() {
      return ~~(((this.verifyAskedAt + (60 * 1000)) - this.nowTime) / 1000)
    },
    ...getters('signupEmail'),
  },
  methods: {
    incrementTime() {
      this.nowTime += 1000
    },
    async reverify() {
      this.verifyAskedAt = Date.now()

      try {
        await this.PATCH_AUTHMANAGEMENT({
          id: null,
          email: this.signupEmail,
          isVerifyTokenRequest: true,
        })
        this.success('Naujas patvirtinimo laiškas išsiųstas!')
      } catch (error) {
        this.setErrorMessage(error)
      }
    },
    ...actions('PATCH_AUTHMANAGEMENT'),
  },
  created() {
    this.verifyAskedAt = Date.now()
    this.success('Sėkmingai užsiregistravai! Prašome patikrinti savo el. pašto dėžutę ir patvirtinti savo el. pašto adresą. Turėtum gauti patvirtinimo laišką per ateinančią minutę.')
    this.interval = setInterval(this.incrementTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style lang="scss">
.SignupThanks {
  background: $white;
  border-radius: $radius 0;
}
</style>
