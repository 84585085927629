<template>
  <Wrap class="sm">
    <div class="Login">
      <FormFloat title="Prisijungti" @submit="login">
        <transition name="slide-down">
          <Notice v-if="message" :class="message.theme">
            {{ message.text }}
          </Notice>
        </transition>
        <!-- <FieldManualCaptcha :value.sync="manualCaptcha"/> -->
        <Field
          autocomplete="email"
          label="El. paštas"
          required
          type="email"
          validation
          :value.sync="email" />
        <Field
          autocomplete="current-password"
          label="Slaptažodis"
          :maxlength="64"
          :minlength="8"
          required
          rules="password|passwordNumber|passwordNotAllNumeric"
          type="password"
          validation
          :value.sync="password" />
        <NoticeRecaptcha />
        <div class="buttons">
          <Btn class="arrow" :isLoading="isLoggingIn" type="submit">
            Prisijungti
          </Btn>
          <Route class="link db" to="LoginForgot">
            <Btn class="arrow minimalistic forgot" styleType="naked">
              Pamiršau slaptažodį
            </Btn>
          </Route>
        </div>
        <!-- <Row class="foot">
          <Column :xs="6">
            <Route class="Btn link db" to="LoginForgot">
              Pamiršau slaptažodį
            </Route>
          </Column>
          <Column :xs="6">
            <Route class="Btn link db" to="Signup">
              Registruotis
            </Route>
          </Column>
        </Row> -->
      </FormFloat>

      <div class="separator">
        <span>arba</span>
      </div>

      <SocialLogin />
      <div class="footer">
        <div class="content">
          <h4>Jungiatės pirmą kartą?</h4>
          <Route class="Btn link minimalistic" to="Signup">
            Registruotis
          </Route>
        </div>
      </div>
    </div>
  </Wrap>
</template>

<script>
import { actions, getters } from './utils'
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import Meta from './mixins/Meta'
import Recaptcha from './mixins/Recaptcha'
import SocialLogin from './components/SocialLogin'

export default {
  mixins: [
    Message,
    Meta('Prisijungimas', undefined, require('@/assets/social/login.jpg')),
    Recaptcha,
  ],
  components: {
    FormFloat,
    SocialLogin,
  },
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false,
    }
  },
  computed: getters(),
  methods: {
    async login() {
      try {
        this.isLoggingIn = true
        await this.LOGIN(await this.injectRecaptcha({
          email: this.email,
          password: this.password,
        }, 'login'))
      } catch (error) {
        this.setErrorMessage((error && error.code === 401)
          ? 'Neteisingas slaptažodis'
          : error)
      } finally {
        this.isLoggingIn = false
      }
    },
    ...actions('LOGIN'),
  },
  watch: {
    isAuth: {
      immediate: true,
      handler(isAuth) {
        if (!isAuth) return
        this.$router.replace({ name: 'SelfProjects' })
      },
    },
  },
}
</script>

<style lang="scss">
.Login {
  background: $white;
  border-radius: $radius 0;

  .buttons {
    padding: 1rem 0.2rem;
    @include sm {
      padding: 1rem 2rem 0;
    }
    @include md {
      padding: 1rem 5rem 0;
    }

    .forgot {
      padding: 0 1rem;
    }
  }

  .separator {
    border-bottom: 2px solid $black;
    line-height: 0.1rem;
    margin: 10px 0 20px;
    text-align: center;
    width: 100%;

    span {
      background: $white;
      padding: 0 10px;
    }
  }

  .SocialLogin {
    background: $white;
  }

  .footer {
    align-items: center;
    background: $blue;
    border-radius: $radius 0;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    text-align: center;

    .content {
      width: 15rem;

      a {
        color: $white;
        margin-top: 1rem;
      }
    }
    // @include md {
    //   padding: 5rem 0;
    // }
  }
}

// #Login-social {
//   border-top: 1px solid $border-color;

//   > h4 {
//     margin: 2.5rem 0 2rem;
//     text-align: center;
//   }
// }
</style>
