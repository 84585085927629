<template>
  <div id="AuthNew">
    <!-- <Hero src="../assets/aboutUs/hero.jpg" /> -->
    <router-view class="auth-view" />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'
// import Hero from '../components/landing/Hero'

export default {
  components: { Footer },
  // methods: actions('FETCH_STATE'),
  // beforeMount() {
  //   this.LOAD(() => {
  //     if (!this.isAuth) {
  //       return this.$router.push({ name: 'Login' })
  //     }

  //     return this.FETCH_STATE()
  //   })
  // },
  mounted() {
    if (this.$route.name === 'SelfServiceLayout') {
      this.$router.replace({ name: 'SelfProjects' })
    }
  },
}
</script>

<style lang="scss">
#AuthNew {
  background: $black-bis;
  background-image: url('../../assets/lines.svg');
  background-position: bottom left;
  background-repeat: round;
  // background-size: 40vw;
  min-height: 100vh;

  > .auth-view {
    // margin-bottom: 7rem;
    // margin-top: 7rem;
    // margin-top: 2rem;
    min-height: 60vh;
    // padding-bottom: 5rem;
    padding-top: 8rem;
    @include sm {
      padding-top: 10rem;
    }
    @include md {
      padding-top: 12rem;
    }
  }

  // > .Hero {
  //   min-height: 10rem;
  //   @include sm {
  //     min-height: 15rem;
  //   }
  //   @include md {
  //     min-height: 20rem;
  //   }
  // }

  .Footer {
    border-top: 2px solid $white;
    margin-top: 6rem;
    @include md {
      margin-top: 10rem;
    }
  }
}
</style>
