<template>
  <Wrap class="sm">
    <FormSignup />
  </Wrap>
</template>

<script>
import FormSignup from './components/forms/FormSignup'
import Meta from './mixins/Meta'
import { getters } from './utils'

export default {
  mixins: [
    Meta(
      'Registracija',
      'Prisijunkite prie SAULĖS BENDRUOMENĖS. Greita registracija internetu: įvesk savo vardą, pavardę, el. pašto adresą, slaptažodį ir sukurk kliento paskyrą. Jau esi registruotas narys? Nurodyk savo el. pašto adresą bei slaptažodį ir prisijunk.',
      require('@/assets/social/signup.jpg'),
    ),
  ],
  components: { FormSignup },
  computed: getters(),
  watch: {
    isAuth: {
      immediate: true,
      handler(isAuth) {
        if (!isAuth) return

        this.$router.replace({ name: 'SelfProjects' })
      },
    },
  },
}
</script>
