<template>
  <div class="container error">
    <transition appear name="slide-fade">
      <Block id="error">
        <div class="code">
          <Icon name="lock" />
        </div>
        <Notice v-if="error" class="danger">
          {{ error }}
        </Notice>
        <div v-else class="message">
          Prisijungiama...
        </div>
        <div class="actions">
          <Btn class="inv" to="Index">
            Grįžti
          </Btn>
        </div>
      </Block>
    </transition>
  </div>
</template>

<script>
import { actions, getters } from './utils'
import Errors from './mixins/Errors'
import { softThrow } from '../bugsnag'

export default {
  mixins: [Errors],
  data() {
    return {
      error: null,
    }
  },
  computed: getters('referrers'),
  methods: {
    ...actions('LOGIN', 'PATCH_USER'),
    login() {
      return this.LOGIN({
        accessToken: this.$route.query.access_token,
        strategy: 'jwt',
      })
    },
    async patchUserMetaReferrers() {
      try {
        await this.PATCH_USER({
          id: this.authUser.id,
          metaReferrers: this.referrers,
        })
      } catch (error) {
        softThrow(error)
      }
    },
  },
  async beforeMount() {
    if (this.$route.query.error) {
      this.error = this.$route.query.error
      return
    }

    try {
      await this.login()

      if (!this.authUser) {
        this.$router.push({ name: 'Login' })
        return
      }

      if (this.authUser.facebookId && this.referrers) {
        await this.patchUserMetaReferrers()
      }

      if (!this.authUser.phone) {
        this.$router.push({ name: 'SelfProfile' })
        return
      }

      this.$router.push({ name: 'SelfProjects' })
    } catch (error) {
      this.error = error.message
    }
  },
}
</script>

<style lang="scss">
</style>
