<template>
  <a class="AuthButton" :class="provider" :href="url">
    <Btn :icon="provider">
      Prisijungti su {{ name }}
    </Btn>
    <!-- <Icon :name="provider" />
    {{ name }} -->
  </a>
</template>

<script>
import { apiUrl } from '../../config'

export default {
  props: {
    name: String,
  },
  computed: {
    url() {
      return `${apiUrl}/oauth/${this.provider}`
    },
    provider() {
      return this.name.toLowerCase()
    },
  },
}
</script>

<style lang="scss">
$_facebook-color: #3b5998;
$_facebook-color-light: lighten($_facebook-color, 10%);
$_google-color: #ea4335;
$_google-color-light: lighten($_google-color, 10%);

.AuthButton {
  &.facebook {
    background: $_facebook-color;
    border: none;

    .Btn {
      &:hover {
        background: $_facebook-color;
        color: $white;
      }
    }

    &:hover, &:focus {
      background: $_facebook-color-light;
    }
  }

  &.google {
    background: $_google-color;
    border: none;

    &:hover, &:focus {
      background: $_google-color-light;
    }
  }
}
</style>
