<template>
  <Wrap>
    <div class="SignupVerify">
      <FormFloat :title="title">
        <Notice v-if="message" :class="message.theme">
          {{ message.text }}
        </Notice>
        <Loader v-if="isVerifying" class="lg info" />
      </FormFloat>
    </div>
  </Wrap>
</template>

<script>
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import Meta from './mixins/Meta'
import { actions } from './utils'
import { jwtName } from 'config'
import { pick } from 'rambda'

export default {
  mixins: [
    Message,
    Meta('Registracija pavyko'),
  ],
  components: { FormFloat },
  data() {
    return {
      isVerifying: true,
      hasVerified: false,
      messageVerifySuccess: 'Sveikiname prisijungus prie saulės bendruomenės!',
    }
  },
  computed: {
    title() {
      if (this.isVerifying) return 'Tikriname jūsų kodą'
      if (this.hasVerified) return 'Sėkmingai patvirtinta. Netrukus būsite prijungti...'
      return 'Nepavyko patvirtinti'
    },
  },
  methods: {
    async createReservation(user) {
      try {
        const {
          capacity,
          projectId,
          promoCode,
          hasSkippedReservation,
          apvaChoice,
          apvaStageId,
        } = pick(
          [
            'capacity',
            'projectId',
            'promoCode',
            'hasSkippedReservation',
            'apvaChoice',
            'apvaStageId',
          ], user.reservationPresignup)

        const { id: reservationId } = await this.CREATE_RESERVATION({
          capacity,
          projectId,
          promoCode,
          hasSkippedReservation,
          apvaChoice,
          apvaStageId,
          userId: user.id,
        })

        this.SET_CAPACITY_CACHE(null)

        this.$router.push({
          name: 'Reservation',
          params: {
            id: reservationId,
            projectId: user.reservationPresignup.projectId,
          },
        })
      } catch {
        this.$router.push({
          name: 'ReserveUser',
          params: {
            amount: Number((user.reservationPresignup.capacity * 100).toFixed(2)),
            id: user.reservationPresignup.projectId,
          },
        })
      }
    },
    ...actions(
      'PATCH_AUTHMANAGEMENT',
      'SET_PROFILE',
      'GET_USER',
      'CREATE_RESERVATION',
      'SET_CAPACITY_CACHE',
    ),
  },
  async mounted() {
    try {
      const { id, accessToken } = await this.PATCH_AUTHMANAGEMENT({
        id: null,
        verifyToken: this.$route.query.token,
      })

      localStorage.setItem(jwtName, accessToken)
      const user = await this.GET_USER(id)
      this.SET_PROFILE(user)
      this.$ga.event('Signup', 'verify', 'email')
      this.hasVerified = true
      this.success('Sveikiname prisijungus prie saulės bendruomenės!')

      if (user.reservationPresignup && Object.keys(user.reservationPresignup).length) {
        await this.createReservation(user)
      } else {
        this.$router.push({ name: 'SelfProjects' }).catch(() => {})
      }
    } catch (error) {
      this.setErrorMessage(error)
    } finally {
      this.isVerifying = false
    }
  },
}
</script>

<style lang="scss">
.SignupVerify {
  background: $white;
  border-radius: $radius 0;
}
</style>
