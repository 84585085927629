<template>
  <Wrap class="sm">
    <FormFloat :title="`Uždrausti laiškų siuntimą į ${email}`" @submit="create">
      <Notice :class="message.theme">
        {{ message.text }}
      </Notice>
      <Field
        label="Atsisakyti naujienlaiškio"
        type="checkbox"
        :value.sync="doUnsubscribe" />
      <Btn :isLoading="isLoading" type="submit">
        Uždrausti laiškų siuntimą
      </Btn>
    </FormFloat>
  </Wrap>
</template>

<script>
import FormFloat from './components/FormFloat'
import Message from './mixins/Message'
import { actions } from './utils'

export default {
  mixins: [Message],
  components: { FormFloat },
  data() {
    return {
      isLoading: false,
      doUnsubscribe: true,
    }
  },
  computed: {
    email() {
      return this.$route.params.email
    },
  },
  methods: {
    async create() {
      try {
        this.isLoading = true
        await this.CREATE_EMAIL_BLOCK({
          email: this.email,
          doUnsubscribe: this.doUnsubscribe,
          key: this.$route.params.key,
        })
      } catch (error) {
        this.setErrorMessage(error)
      } finally {
        this.success('Adresas užblokuotas.')
        this.isLoading = false
      }
    },
    ...actions('CREATE_EMAIL_BLOCK'),
  },
  created() {
    this.warning(`Ši forma leis jums nustoti gauti laiškus į ${this.email}. Aktyvuoti šią formą reikia tik tada, kai į savo paštą gaunate daug neprašytų žinučių, tikėtinai sukurtų kito žmogaus, kuris įrašo jūsų adresą į mūsų registracijos/kontaktų formas.`)
  },
}
</script>
