<template>
  <Wrap class="sm">
    <div class="Logout">
      <!-- <transition appear name="slide-fade">
        <Block id="error">
          <div class="body">
            <div class="message">
              Atsijungiama...
            </div>
            <div class="actions">
              <Btn class="inv" to="Index">
                Į pagrindinį
              </Btn>
            </div>
          </div>
        </Block>
      </transition> -->
      <FormFloat title="Atsijungimas">
        <transition appear name="slide-fade">
          <Notice class="info">
            Atsijungiama...
          </Notice>
        </transition>
        <div class="actions">
          <Btn class="inv" to="Index">
            Į pagrindinį
          </Btn>
        </div>
      </FormFloat>
    </div>
  </Wrap>
</template>

<script>
import Errors from './mixins/Errors'
import FormFloat from './components/FormFloat'
import { actions } from './utils'

export default {
  mixins: [Errors],
  components: { FormFloat },
  methods: actions('LOGOUT'),
  async beforeMount() {
    await this.LOGOUT()
    this.$router.push({ name: 'Index' })
  },
}
</script>

<style lang="scss">
.Logout {
  background: $white;
  border-radius: $radius 0;
}
</style>
