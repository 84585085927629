<template>
  <div class="SocialLogin">
    <Row>
      <Column v-if="offset" :xs="offset" />
      <Column v-for="social in socials" :key="social" :sm="~~(12 / socials.length) - (2 * offset)">
        <AuthButton :name="social" />
      </Column>
    </Row>
  </div>
</template>

<script>
import AuthButton from './AuthButton'

export default {
  components: { AuthButton },
  data() {
    return {
      socials: [
        'facebook',
        // 'google',
      ],
    }
  },
  computed: {
    offset() {
      return this.socials.length === 1
        ? 2
        : 0
    },
  },
}
</script>

<style lang="scss">
.SocialLogin {
  padding: 2rem;
}
</style>
